const linesToCheck = [
    'Title',
    'Date',
    'Author',
    'Author_slug',
    'Author_image',
    'Author_description',
    'Category',
    'Breadcrumb',
    'Slug',
    'Tags',
    'breadcrumb_children',
    'breadcrumb_url',
    'description',
    'thumbnail',
];

export default linesToCheck;
